import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {style, state, animate, transition, trigger} from '@angular/animations';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {HttpCustomService} from '../http-custom.service';
import {hasClass} from '../constant';
declare const Waypoint: any;
declare var $: any;
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [   // :enter is alias to 'void => *'
                style({opacity: 0}),
                animate(500, style({opacity: 1}))
            ]),
            transition(':leave', [   // :leave is alias to '* => void'
                animate(500, style({opacity: 0}))
            ])
        ])
    ]
})
export class ContactComponent implements OnInit, AfterViewInit {
    form: FormGroup;
    err: any = {};
    toggleAlert: boolean = false;
    @ViewChild('loader', {static: true}) loader: any;
    constructor(private formBuilder: FormBuilder, private httpCustomService: HttpCustomService) {}
    ngOnInit() {
        this.form = this.formBuilder.group({
            fullName: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            phoneNo: [null, []],
            desc: [null, [Validators.required,Validators.pattern(new RegExp("\\S"))]],
        });
    }
    public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : {'whitespace': true};
    }
    getErrorMsg(field: string) {
        if(!this.form.get(field).valid)
        switch(field){
        case "fullName": return "Please enter full name";
        case "email": 
            if(!this.form.get(field).value || this.form.get(field).value.trim() == ""){
                return "Please enter email address";    
            } else {
                return "Please enter valid email address";    
            }
        case "desc": return "Please enter description";
        }
    }
    ngAfterViewInit(): void {
        this.loadWayPoints();
    }
    loadWayPoints(): void {
        let elemArr = document.getElementsByClassName('element-animate');
        for (let i = 0; elemArr.length > i; i++) {
            new Waypoint({
                element: elemArr[i],
                handler: function (direction: any) {
                    if (direction === 'down' && !hasClass(this.element, 'element-animated')) {
                        this.element.classList.add('item-animate');
                        setTimeout(function () {
                            let arrAllElem: any[] = $('body .element-animate.item-animate');
                            for (let j = 0; arrAllElem.length > j; j++) {
                                var effect = $(arrAllElem[j]).data('animate-effect');
                                if (effect === 'fadeIn') {
                                    arrAllElem[j].classList.add('fadeIn');
                                    arrAllElem[j].classList.add('element-animated');
                                } else if (effect === 'fadeInLeft') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInLeft');
                                } else if (effect === 'fadeInRight') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInRight');
                                } else {
                                    arrAllElem[j].classList.add('fadeInUp');
                                    arrAllElem[j].classList.add('element-animated');
                                }
                                arrAllElem[j].classList.remove('item-animate');
                                arrAllElem[j].classList.remove('element-animate');
                            }
                        }, 100);
                    }
                },
                offset: '95%'
            });
        }
    }
    submitForm(event: Event) {
        event.preventDefault();
        this.err.fullName = this.getErrorMsg('fullName');
        this.err.email = this.getErrorMsg('email');
        this.err.desc = this.getErrorMsg('desc');
        if (this.err.fullName || this.err.email || this.err.desc) {
            return false;
        }
        this.loader.nativeElement.classList.add('show');
        this.httpCustomService.send(this.form.value).subscribe(res => {
            this.toggleAlert = true;
            this.loader.nativeElement.classList.remove('show');
            this.form.reset();
            setTimeout(() => {
                this.toggleAlert = false;
            }, 5000);
        });
    }
}
