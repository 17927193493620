import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {GalleryComponent} from './gallery/gallery.component';
import {EventTypeComponent} from './event-type/event-type.component';
import {AchievementsComponent} from './achievements/achievements.component';
import {ContactComponent} from './contact/contact.component';
import {SitemapComponent} from './sitemap/sitemap.component';

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'home', component: HomeComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'events', component: EventTypeComponent},
    {path: 'achievements', component: AchievementsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'sitemap', component: SitemapComponent},
    {path: '**', redirectTo: 'home'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
