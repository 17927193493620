import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {OwlModule} from 'ngx-owl-carousel';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ParallaxModule} from 'ngx-parallax';
import {HttpClientModule} from '@angular/common/http';
import {EmbedVideo} from 'ngx-embed-video';
import {SafePipe} from './safe-url.pipe';
import {HomeComponent} from './home/home.component';
import {GalleryComponent} from './gallery/gallery.component';
import {NgxImageGalleryModule} from 'ngx-image-gallery';
import {EventTypeComponent} from './event-type/event-type.component';
import {AchievementsComponent} from './achievements/achievements.component';
import {ContactComponent} from './contact/contact.component';
import { CommonModule } from '@angular/common';
import { SitemapComponent } from './sitemap/sitemap.component';
@NgModule({
    declarations: [
        AppComponent, SafePipe, HomeComponent, GalleryComponent, EventTypeComponent, AchievementsComponent, ContactComponent, SitemapComponent
    ],
    imports: [
        BrowserModule, ReactiveFormsModule, 
        AppRoutingModule, OwlModule, BrowserAnimationsModule, ParallaxModule, HttpClientModule, EmbedVideo.forRoot(), NgxImageGalleryModule, CommonModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
