import {Component, HostListener, ViewChild, OnInit, AfterViewInit, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {trigger, transition, animate, style, state} from '@angular/animations';
declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [
        trigger('openClose', [
            state('true', style({opacity: 1, 'margin-top': '35px'})),
            state('false', style({height: '0px', opacity: 0, 'margin-top': '4px', 'pointer-events': 'none'})),
            state('large', style({height: '0px', opacity: 0, 'margin-top': '4px'})),
            transition('false => true', animate(500)),
            transition('true => false', animate(500))
        ])
    ]
})
export class AppComponent implements OnInit, AfterViewInit {

    title = 'EventManagement';
    isMobileView: boolean = false;
    @ViewChild('navbarsBTN', {static: true}) element: any;
    @ViewChild('loader', {static: true}) loader: any;
    buttonClicked: boolean = false;
    menuClose: string = 'large';
    constructor(private route: Router, private meta: Meta,
        private titleService: Title) {

    }
    ngOnInit(): void {
        this.titleService.setTitle('Anchor Mona Solanki - Gujarat');
        this.meta.addTag({
            name: 'anchor.mona',
            content: 'Anchor mona solanki'
        });
        this.meta.updateTag(
            {
                name: 'description',
                content: 'Anchor, Mona, Solanki, Gujarat, Mona Solanki, Anchor Mona Solanki, Host, Emcee'
            });
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loader.nativeElement.classList.remove('show');
        }, 20);
        this.setResponsive();

    }

    windowScrolled: boolean;
    scrollToTop() {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e: any) {
        let element = document.querySelector('#navbar');
        if (window.pageYOffset > element.clientHeight) {
            element.classList.add('navbar-inverse');
        } else {
            element.classList.remove('navbar-inverse');
        }
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 200) {
            this.windowScrolled = true;
        }
        else if (window && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }
    @HostListener("document: click", ["event"])
    private clickedOutside(event): void {
        if (this.buttonClicked == true) {
            setTimeout(() => {this.menuClose = (this.menuClose === 'true') ? 'false' : 'true'}, 1);
            this.buttonClicked = false;
        } else if (this.isMobileView === false) {
            setTimeout(() => this.menuClose = 'large', 50);
        } else {
            setTimeout(() => this.menuClose = 'false', 50);
        }
    }
    menuToggle() {
        this.buttonClicked = true;
    }
    goToHome(path: string) {
        if (window.location.pathname == path) {
            this.scrollToTop();
        } else {
            this.route.navigate([path]);
        }
    }
    setResponsive() {
        let elem = document.querySelector('#navbar');
        if (window.innerWidth < 400) {
            this.isMobileView = true;
            setTimeout(() => this.menuClose = 'false', 1);
        }
        if (window.innerWidth < 767) {
            this.isMobileView = true;
            setTimeout(() => this.menuClose = 'false', 1);
            elem.classList.add('navbar-expand-xm');
        } else if (window.innerWidth > 767) {
            this.isMobileView = false;
            setTimeout(() => this.menuClose = 'large', 1);
            elem.classList.remove('navbar-expand-xm');
        }
    }
    @HostListener('window:resize', ['$event'])
    onResize(e: any) {
        this.setResponsive();
    }
}
