import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF} from "ngx-image-gallery";
import {hasClass} from '../constant';
declare const Waypoint: any;
declare var $: any;
@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit, AfterViewInit {

    constructor() {}
    // get reference to gallery component
    @ViewChild(NgxImageGalleryComponent, {static: true}) ngxImageGallery: NgxImageGalleryComponent;

    // gallery configuration
    conf: GALLERY_CONF = {
        imageOffset: '0px',
        showDeleteControl: false,
        showImageTitle: false,
    };

    // gallery images
    images: GALLERY_IMAGE[] = [];

    Images = [
        {
            path: 'assets/img/slider-1.png',
            content: 1
        },
        {
            path: 'assets/img/slider-2.jpg',
            content: 2
        }
    ];
    SlideOptions = {
        loop: true,
        autoplay: true,
        margin: 10,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        nav: true,
        dots: false,
        autoplayHoverPause: false,
        items: 1,
        navText: ["<span class='ion-chevron-left'></span>", "<span class='ion-chevron-right'></span>"],
        responsive: {0: {items: 1, nav: false}, 600: {items: 1, nav: false}, 1000: {items: 1, nav: true}}
    };

    ngOnInit() {
        [46, 45,27, 1, 2, 28, 36, 37, 38, 39, 40, 41, 42, 43, 44, 29, 30, 31, 32, 33, 34, 35, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26].forEach(i => {
            this.images.push(
                {
                    url: `assets/img/gallery/mona-${i}.jpg`,
                    thumbnailUrl: `assets/img/gallery/mona-t-${i}.jpg`,
                    altText: `mona-t-${i}.jpg`
                }
            );
        });
    }
    ngAfterViewInit(): void {
        this.loadWayPoints();
    }

    loadWayPoints(): void {
        let elemArr = document.getElementsByClassName('element-animate');
        for (let i = 0; elemArr.length > i; i++) {
            new Waypoint({
                element: elemArr[i],
                handler: function (direction: any) {
                    if (direction === 'down' && !hasClass(this.element, 'element-animated')) {
                        this.element.classList.add('item-animate');
                        setTimeout(function () {
                            let arrAllElem: any[] = $('body .element-animate.item-animate');
                            for (let j = 0; arrAllElem.length > j; j++) {
                                var effect = $(arrAllElem[j]).data('animate-effect');
                                if (effect === 'fadeIn') {
                                    arrAllElem[j].classList.add('fadeIn');
                                    arrAllElem[j].classList.add('element-animated');
                                } else if (effect === 'fadeInLeft') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInLeft');
                                } else if (effect === 'fadeInRight') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInRight');
                                } else {
                                    arrAllElem[j].classList.add('fadeInUp');
                                    arrAllElem[j].classList.add('element-animated');
                                }
                                arrAllElem[j].classList.remove('item-animate');
                                arrAllElem[j].classList.remove('element-animate');
                            }
                        }, 100);
                    }
                },
                offset: '95%'
            });
        }
    }
    galleryOpened(index) {
    }

    galleryClosed() {
    }

    galleryImageClicked(index) {
    }

    galleryImageChanged(index) {
    }

    deleteImage(index) {
    }
    openGallery(index: number = 0) {
        this.ngxImageGallery.open(index);
    }

    closeGallery() {
        this.ngxImageGallery.close();
    }

    newImage(index: number = 0) {
        this.ngxImageGallery.setActiveImage(index);
    }

    nextImage() {
        this.ngxImageGallery.next();
    }

    // prev image in gallery
    prevImage() {
        this.ngxImageGallery.prev();
    }
}
