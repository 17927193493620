import {Component, OnInit, AfterViewInit, Inject} from '@angular/core';
import {hasClass} from '../constant';
declare const Waypoint: any;
declare var $: any;
@Component({
    selector: 'app-event-type',
    templateUrl: './event-type.component.html',
    styleUrls: ['./event-type.component.css']
})
export class EventTypeComponent implements OnInit, AfterViewInit {
    selectId: string;
    constructor() {}

    ngOnInit() {
    }
    eventType: any[] = [
        {eName: "Wedding Occasions", path:"assets/img/gallery/mona-t-42.jpg", desc: "Having an Host can take your wedding to the next level. Some Masti with couple, Couple want to have all the entertainment in their weddings these days and entertainment options in wedding are unlimited today. Entertainment options in a wedding event are not just limited to the Music, DJ, and live artist’s performances. There is one more thing, one more aspect which pulls all these acts together, and that is the Master of Ceremony (MC) or anchor."},
        {eName: "Coorporate Events", path:"assets/img/gallery/mona-t-45.jpg", desc: "An Anchor always Made your relation with your customers or clients so memorable in your company events. This is the more interesting way to make your boring Company event into a Entertaining Business party."},
        {eName: "Birthday Parties", path:"assets/img/gallery/mona-t-6.jpg", desc: "We always take care of your beloved member's birthday party, as it's a special day in your life to celebrate a birthday party with him/her. Either its a Small baby or your partner or your parent, Host always make your party memorable."},
        {eName: "Youngster Parties", path:"assets/img/gallery/mona-t-43.jpg", desc: "Youngsters always create a chance to celebrate their days like Friendship Day, New Year Parties, Dj Bash, Valentine day etc.. With a unique way and and cheering energetic anchor plays a special role in their parties to make them enjoy fully."},
        {eName: "Festival Celebration", path:"assets/img/gallery/mona-t-12.jpg", desc: "Host always understand your precious relations with family specially on Festivals like Holi, Diwali,  Uttrayan, Navratri. So with full of package of entertainment you can throw a diwali party confidently and With Anchor in Garba Nights make your Navratri Days Special."},
        {eName: "Business Events", path:"assets/img/corpo.jpg", desc: "With your Local business or either Brand Business always Satiesfied your customers with one annual meeting with full on masti and entertaining way to make your day or business memorable. Either its Inaugration or Anniversary Ceremony of your business, anchor always Stand beside you to make your party successfull."},
        {eName: "Kitty Parties", path:"assets/img/gallery/mona-t-4.jpg", desc: "When it comes to ladies, they never fail to organize a special party for their ladies to make and have a fun with them. And again Anchor plays a important role to make your event with full of blaster."},
        {eName: "School Functions", path:"assets/img/gallery/mona-t-41.jpg", desc: "Suppose you are celebrating your day either it's freshers Party, Annual Function, Farewell Party etc with your friends and more than just Dance, if you're allowed to have a special task or fun on stage with your gang it'll be more memorable. So, Anchor gives you a chance to show your Strength of friendship."},
        {eName: "Product Launching Events", path:"assets/img/gallery/mona-t-40.jpg", desc: "When a product is a new in a market which is very innovative but people don't have any basic knowledge regarding the product than host again aware the public attention so that, everybody takes interest towards your product."},
        {eName: "Government Events", path:"assets/img/gallery/mona-t-38.jpg", desc: "Conferences, on the other hand, typically have multiple sessions. They are usually held at hotels, beginning with a keynote session and then offering breakout sessions by topic. Development and Organizational planning are typically the topics of the agenda for these retreats, but equal weight is given to enjoyable activities as part of the original incentive and reward."},
        {eName: "Sport Days", path:"assets/img/sports.jpg", desc: " To feel energetic and powerful during the time of Sport days, Anchor Never weakens the player infact, they fill the player with full of enthusiasm and passion in their mind that they can do it and Will Win Surely and Definitely. Commentary was also a good part of an anchor. "},
        {eName: "Fashion Events", path:"assets/img/gallery/mona-t-44.jpg", desc: "When it comes to the fashion world Event, Anchor herself also represents like a beautiful model to make the environment more beautiful with her glance. Hosting fashion events helps the judges to understand the flow or sequence of the show."},
        {eName: "Award Ceremonies", path:"assets/img/gallery/mona-t-39.jpg", desc: "There is nothing like simple shows where name is announcing and you are going on stage to take your price or award. Event host always makes fun with the winners of the days to make laugh everyone. You can make boring award ceremony function into a Fun entertaining event."},
        {eName: "College Fests", path:"assets/img/gallery/mona-t-37.jpg", desc: "When it comes to life of college, every person or collegian wants to enjoy fully as much as he can, So again Festivals like technical or Days like Signature day, Host will make your day more memorable with your loving friends."},
        {eName: "Game Jockey", path:"assets/img/gallery/mona-t-17.jpg", desc: "Game jockey implies a person, who will mix games during a live performance, by adapting to the players’ feelings. We will present key concepts of the DJ practice and the similarities to the game universe. Based a Jockey – Game – Players triangle, we offer a creation research that tries to evaluate the possible figure of the jockey and the use of game samples."}
    ];
    ngAfterViewInit(): void {
        this.loadWayPoints();

        if (window.history.state.data) {

            setTimeout(() => {
                this.selectId = window.history.state.data;
                this.scroll("#", this.selectId);
            }, 1000);
        }
    }

    scroll(idd: string, id: string) {
        const elementList = document.querySelectorAll(idd + id);
        const element = elementList[0] as HTMLElement;
        const rect = element.getBoundingClientRect();
        window.scrollTo(0, rect.top + window.pageYOffset - document.documentElement.clientTop - 200);
        element.classList.add('master-color');
        setTimeout(() => {
            element.classList.remove('master-color');
        }, 1000);
    }
    loadWayPoints(): void {
        let elemArr = document.getElementsByClassName('element-animate');
        for (let i = 0; elemArr.length > i; i++) {
            new Waypoint({
                element: elemArr[i],
                handler: function (direction: any) {
                    if (direction === 'down' && !hasClass(this.element, 'element-animated')) {
                        this.element.classList.add('item-animate');
                        setTimeout(function () {
                            let arrAllElem: any[] = $('body .element-animate.item-animate');
                            for (let j = 0; arrAllElem.length > j; j++) {
                                var effect = $(arrAllElem[j]).data('animate-effect');
                                if (effect === 'fadeIn') {
                                    arrAllElem[j].classList.add('fadeIn');
                                    arrAllElem[j].classList.add('element-animated');
                                } else if (effect === 'fadeInLeft') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInLeft');
                                } else if (effect === 'fadeInRight') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInRight');
                                } else {
                                    arrAllElem[j].classList.add('fadeInUp');
                                    arrAllElem[j].classList.add('element-animated');
                                }
                                arrAllElem[j].classList.remove('item-animate');
                                arrAllElem[j].classList.remove('element-animate');
                            }
                        }, 100);
                    }
                },
                offset: '95%'
            });
        }
    }
}
