import {Component, OnInit, AfterViewInit} from '@angular/core';
import {hasClass} from '../constant';
declare const Waypoint: any;
declare var $: any;
@Component({
    selector: 'app-sitemap',
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit, AfterViewInit {

    constructor() {}

    ngOnInit() {
    }
    ngAfterViewInit(): void {
        this.loadWayPoints();
    }

    loadWayPoints(): void {
        let elemArr = document.getElementsByClassName('element-animate');
        for (let i = 0; elemArr.length > i; i++) {
            new Waypoint({
                element: elemArr[i],
                handler: function (direction: any) {
                    if (direction === 'down' && !hasClass(this.element, 'element-animated')) {
                        this.element.classList.add('item-animate');
                        setTimeout(function () {
                            let arrAllElem: any[] = $('body .element-animate.item-animate');
                            for (let j = 0; arrAllElem.length > j; j++) {
                                var effect = $(arrAllElem[j]).data('animate-effect');
                                if (effect === 'fadeIn') {
                                    arrAllElem[j].classList.add('fadeIn');
                                    arrAllElem[j].classList.add('element-animated');
                                } else if (effect === 'fadeInLeft') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInLeft');
                                } else if (effect === 'fadeInRight') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInRight');
                                } else {
                                    arrAllElem[j].classList.add('fadeInUp');
                                    arrAllElem[j].classList.add('element-animated');
                                }
                                arrAllElem[j].classList.remove('item-animate');
                                arrAllElem[j].classList.remove('element-animate');
                            }
                        }, 100);
                    }
                },
                offset: '95%'
            });
        }
    }
}
