import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpCustomService {


    constructor(private http: HttpClient) {}

    send(data: any) {
        return this.http.get(`${environment.apiUrl}fullName=${data.fullName}&email=${data.email}&phoneNo=${data.phoneNo}&desc=${data.desc}`);
    }
}
