import {Component, AfterViewInit, HostListener, Inject} from '@angular/core';
import {Router,NavigationExtras} from '@angular/router';
import {hasClass} from '../constant';
import {interval} from 'rxjs';
import {EmbedVideoService} from 'ngx-embed-video';
declare const Waypoint: any;
declare var $: any;
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {
    timerInit: any = {events: 0, clients: 0};
    timerInitIntV = interval(60);
    selectedVideo: any;
    videoHeight: number;

    constructor(private embedService: EmbedVideoService, private router: Router) {

        setTimeout(() => {
            this.initSliderImage(window.innerWidth);
        }, 0);
        let invl = this.timerInitIntV.subscribe(n => {

            if (this.timerInit.events < 110) {
                this.timerInit.events++;
            };

            this.timerInit.clients++;
            if (this.timerInit.clients >= 150) {
                invl.unsubscribe();
            }
            //            this.loadTimer();
        });

    }
    Images = window.innerWidth > 800 ? [{path: 'assets/img/slider-1.jpg', content: 1},
    {path: 'assets/img/slider-2.jpg', content: 2}] : [{
        path: 'assets/img/slider-3.jpg', content: 1
    }, {path: 'assets/img/slider-4.jpg', content: 2}];
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.initSliderImage(event.target.innerWidth);
    }
    initSliderImage(w: number) {
        if (w > 800) {
            this.Images[0].path = 'assets/img/slider-1.jpg';
            this.Images[1].path = 'assets/img/slider-2.jpg';
        } else {
            this.Images[0].path = 'assets/img/slider-3.jpg';
            this.Images[1].path = 'assets/img/slider-4.jpg';
        }
    }

    changeVideoHeight() {
        return window.innerHeight - 20;
    }

    ngAfterViewInit() {
        this.changeVideoHeight();
        this.loadWayPoints();
        this.loadYouTube();

    }
    youTubeUrls: any[] = [
        {
            urlVideo: 'https://www.youtube.com/embed/Wdc4_lr86wc',
            url: 'https://www.youtube.com/watch?v=Wdc4_lr86wc',
            options: {image: 'maxresdefault'}
        },
        {
            urlVideo: 'https://www.youtube.com/embed/A_Jo_qbkvvw',
            url: 'https://www.youtube.com/watch?v=A_Jo_qbkvvw',
            options: {image: 'sddefault'}
        },
        {
            urlVideo: 'https://www.youtube.com/embed/sHY-Z3hicZY',
            url: 'https://www.youtube.com/watch?v=sHY-Z3hicZY',
            options: {image: 'maxresdefault'}
        },
        {
            urlVideo: 'https://www.youtube.com/embed/YJ80Dx8U64g',
            url: 'https://www.youtube.com/watch?v=YJ80Dx8U64g',
            options: {image: 'maxresdefault'}
        },
        {
            urlVideo: 'https://www.youtube.com/embed/UmO5iZm1HU0',
            url: 'https://www.youtube.com/watch?v=UmO5iZm1HU0',
            options: {image: 'maxresdefault'}
        }
    ];
    customerReview = [
        {
            title: "Atul Sharma",
            content: "Undoubtedly the number one anchor of the era. Her energy is unmatchable. Superb sense of humor. Over the top funny characters and crowd engaging personality.<br>#Show must go on..."
        }, {
            title: "Anuj Mishra",
            content: "If u want to make your event amazing then u must choose her...she is so awesome..her voice is magical Nd she is very attractive."

        }, {
            title: "Meet Prajapati",
            content: "As anchor, actress, friend, human and everywhere she is Best Ever. A unique person with full package of amazing talents."
        }, {
            title: "Maitri Brahmkshatriya",
            content: "You are always the best at what you do! Best entertaining and most energetic ever!"
        }, {
            title: "Mayank Rathod",
            content: "We are thankful to have a best and good and sweet voice anchor in our mehsana, I saw you live in many contest, Great work, good job ...."
        }, {
            title: "Chirag Bhojak",
            content: "Best  Entertaining and Attractive Anchor in Gujarat. Loved your Work"
        }, {
            title: "Mansi Desai",
            content: "Mona you are a great anchor... Full of energy, excitement and a happiness bubble Always looking forward for yur presence in parties and funtions..... Parties ki jaan Mona Solanki"
        }, {
            title: "Tejal Shah",
            content: "Really super Anchoring n u looking Fabulous Badhane bau j gmi tmari speech bs mara  darek  pn program ma tme fix thae gya<br>Ty so much"
        }, {
            title: "Jinesh Shah",
            content: "Hi miss,<br>It's jeenesh shah from unjha Your yesterday's performance was great Thanks for hosting and make my occasion memorable. <br>You are Mind blowing."
        }
    ];
    SlideOptions = {
        loop: true,
        autoplay: true,
        margin: 10,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        nav: true,
        dots: false,
        autoplayHoverPause: false,
        items: 1,
        navText: ["<span class='ion-chevron-left'></span>", "<span class='ion-chevron-right'></span>"],
        responsive: {0: {items: 1, nav: false}, 600: {items: 1, nav: false}, 1000: {items: 1, nav: true}}
    };
    SlideOptionsReview = {
        loop: true,
        autoplay: true,
        margin: 10,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        items: 1,
        navText: ["<span class='ion-chevron-left'></span>", "<span class='ion-chevron-right'></span>"],
        responsive: {0: {items: 1, nav: false}, 600: {items: 1, nav: false}, 1000: {items: 1, nav: true}}
    };

    eventType: any[] = [
        {eName: "Wedding Occasions", desc: ""},
        {eName: "Coorporate Events", desc: ""},
        {eName: "Birthday Parties", desc: ""},
        {eName: "Youngster Parties", desc: ""},
        {eName: "Festival Celebration", desc: ""},
        {eName: "Business Events", desc: ""},
        {eName: "Kitty Parties", desc: ""},
        {eName: "School Functions", desc: ""},
        {eName: "Product Launching Events", desc: ""},
        {eName: "Government Events", desc: ""},
        {eName: "Sport Days", desc: ""},
        {eName: "Fashion Events", desc: ""},
        {eName: "Award Ceremonies", desc: ""},
        {eName: "College Fests", desc: ""},
        {eName: "Game Jockey", desc: ""}
    ];

    scroll(idd: string, id: string) {
        const elementList = document.querySelectorAll(idd + id);
        const element = elementList[0] as HTMLElement;
        element.scrollIntoView({behavior: 'smooth'});
    }

    loadYouTube() {
        for (let i = 0; i < this.youTubeUrls.length; i++) {

            this.embedService.embed_image(this.youTubeUrls[i].url,
                this.youTubeUrls[i].options).then((res:any) => {
                    this.youTubeUrls[i].thumbnail = res.html;
                });
        }
    }
    goToEvents(id: string): void {
        this.router.navigate(['/events'], {state: {data: id}});
    }
    playVideo(inx: any) {
        this.selectedVideo = inx;
        $(".modal").on('hide.bs.modal', function () {
            let element = document.querySelector("#videoFrame");
            element.setAttribute("src", element.getAttribute("src"));
        });
    }
    loadTimer() {
        var mySec = new Date("2021-03-25").getTime() - new Date().getTime();
        this.timerInit.seconds = Math.abs(mySec);
        this.timerInit.weeks = ((this.timerInit.seconds + 1) / 86400000);
        this.timerInit.weeks = Math.ceil(this.timerInit.weeks / 7).toString();
        this.timerInit.seconds /= 1000;
        this.timerInit.days = Math.floor(this.timerInit.seconds / 86400);
        this.timerInit.seconds -= this.timerInit.days * 86400;
        this.timerInit.hours = Math.floor(this.timerInit.seconds / 3600) % 24;
        this.timerInit.seconds -= this.timerInit.hours * 3600;
        this.timerInit.minutes = Math.floor(this.timerInit.seconds / 60) % 60;
        this.timerInit.seconds -= this.timerInit.minutes * 60;
        this.timerInit.seconds = Math.round(this.timerInit.seconds % 60);
    }
    loadWayPoints(): void {
        let elemArr = document.getElementsByClassName('element-animate');
        for (let i = 0; elemArr.length > i; i++) {
            new Waypoint({
                element: elemArr[i],
                handler: function (direction: any) {
                    if (direction === 'down' && !hasClass(this.element, 'element-animated')) {
                        this.element.classList.add('item-animate');
                        setTimeout(function () {
                            let arrAllElem: any[] = $('body .element-animate.item-animate');
                            for (let j = 0; arrAllElem.length > j; j++) {
                                var effect = $(arrAllElem[j]).data('animate-effect');
                                if (effect === 'fadeIn') {
                                    arrAllElem[j].classList.add('fadeIn');
                                    arrAllElem[j].classList.add('element-animated');
                                } else if (effect === 'fadeInLeft') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInLeft');
                                } else if (effect === 'fadeInRight') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInRight');
                                } else {
                                    arrAllElem[j].classList.add('fadeInUp');
                                    arrAllElem[j].classList.add('element-animated');
                                }
                                arrAllElem[j].classList.remove('item-animate');
                                arrAllElem[j].classList.remove('element-animate');
                            }
                        }, 100);
                    }
                },
                offset: '95%'
            });
        }
    }

}
