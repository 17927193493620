import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF} from "ngx-image-gallery";
import {hasClass} from '../constant';
declare const Waypoint: any;
declare var $: any;
@Component({
    selector: 'app-achievements',
    templateUrl: './achievements.component.html',
    styleUrls: ['./achievements.component.css']
})
export class AchievementsComponent implements OnInit, AfterViewInit {

    constructor() {}

    // get reference to gallery component
    @ViewChild(NgxImageGalleryComponent, {static: true}) ngxImageGallery: NgxImageGalleryComponent;

    // gallery configuration
    conf: GALLERY_CONF = {
        imageOffset: '0px',
        showDeleteControl: false,
        showImageTitle: false,
    };

    // gallery images
    images: GALLERY_IMAGE[] = [];
    celebrities: GALLERY_IMAGE[] = [];
    celebName: string[] = [
        "Piyush Patel and Zeenat Shaikh<br>(Guj. Actor &amp; Actress)",
        "",
        "Sanjay Gordia&nbsp;(Bollywood and Guj. Comedian Actor)",
        "RJ Dipali",
        "Gaman Santhal <br>(Famous Guj. Singer)",
        "Maulik Nayak, Hemang Shah, Mehul Solanki (Guj. Actors)",
        "Vaibhav Ghuge (DID Fame, Dancing Superstar Fame, Dance+ Choreographer, Nach Baliye Choreographer)"
    ];
    ngOnInit() {

        [1, 2, 3, 4].forEach(i => {
            this.images.push(
                {
                    url: `assets/img/client-${i}.jpg`,
                    thumbnailUrl: `assets/img/client-${i}.jpg`
                }
            );
        });
        [1, 2, 3, 4, 5, 6, 7].forEach(i => {
            this.celebrities.push(
                {
                    url: `assets/img/celebrities/celebrities-${i}.jpg`,
                    thumbnailUrl: `assets/img/celebrities/celebrities-t-${i}.jpg`,
                    altText: this.celebName[i - 1],
                    title: this.celebName[i - 1]
                }
            );;
        });
    }
    ngAfterViewInit(): void {
        this.loadWayPoints();
    }

    loadWayPoints(): void {
        let elemArr = document.getElementsByClassName('element-animate');
        for (let i = 0; elemArr.length > i; i++) {
            new Waypoint({
                element: elemArr[i],
                handler: function (direction: any) {
                    if (direction === 'down' && !hasClass(this.element, 'element-animated')) {
                        this.element.classList.add('item-animate');
                        setTimeout(function () {
                            let arrAllElem: any[] = $('body .element-animate.item-animate');
                            for (let j = 0; arrAllElem.length > j; j++) {
                                var effect = $(arrAllElem[j]).data('animate-effect');
                                if (effect === 'fadeIn') {
                                    arrAllElem[j].classList.add('fadeIn');
                                    arrAllElem[j].classList.add('element-animated');
                                } else if (effect === 'fadeInLeft') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInLeft');
                                } else if (effect === 'fadeInRight') {
                                    arrAllElem[j].classList.add('element-animated');
                                    arrAllElem[j].classList.add('fadeInRight');
                                } else {
                                    arrAllElem[j].classList.add('fadeInUp');
                                    arrAllElem[j].classList.add('element-animated');
                                }
                                arrAllElem[j].classList.remove('item-animate');
                                arrAllElem[j].classList.remove('element-animate');
                            }
                        }, 100);
                    }
                },
                offset: '95%'
            });
        }
    }
    galleryOpened(index) {
    }

    galleryClosed() {
    }

    galleryImageClicked(index) {
    }

    galleryImageChanged(index) {
    }

    deleteImage(index) {
    }
    openGallery(index: number = 0) {
        this.ngxImageGallery.open(index);
    }

    closeGallery() {
        this.ngxImageGallery.close();
    }

    newImage(index: number = 0) {
        this.ngxImageGallery.setActiveImage(index);
    }

    nextImage() {
        this.ngxImageGallery.next();
    }

    // prev image in gallery
    prevImage() {
        this.ngxImageGallery.prev();
    }
}
